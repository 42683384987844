import React from "react"
import { graphql } from "gatsby"
import LinkWithIcon from "../components/LinkWithIcon"
import Image from "gatsby-image"
import Layout from "../components/layout"
import { mdiGoogleMaps } from "@mdi/js"
import Icon from "@mdi/react"
import Seo from "../components/seo"
import BottomCardAddress from "../components/BottomCardAddress"

const GoogleMapInfo = ({ title, address }) => {
  let q = (title + " " + address).replace(/\r?\n/, ",").replace(/\s+/g, " ")
  let uri = "//google.com/maps/search/?api=1&query=" + encodeURIComponent(q)
  return (
    <LinkWithIcon uri={uri}>
      <Icon className="mr-1" title={title} path={mdiGoogleMaps} size="1rem" />
      {title}
    </LinkWithIcon>
  )
}

const AddressLink = ({ title, address }) => {
  if (address) {
    return (
      <div className="flex-1">
        <p className="text-sm md:text-base text-gray-800 flex items-center">
          <GoogleMapInfo title={title} address={address} />
        </p>
      </div>
    )
  }
  return <p></p>
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const {
      title,
      address,
      tel,
      thumbnail,
      www,
      tags,
      city,
      email,
      type,
    } = post.frontmatter
    const isBottom = tel && title && address && www
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title={city || title}
          tags={tags}
          description={post.frontmatter.type || post.excerpt}
        />
        <article className="container mx-auto">
          <header className="pt-6">
            <div className="flex">
              <h1 className="w-8/12 px-2 md:px-1">{title}</h1>
            </div>
            {isBottom && (
              <div className="flex">
                <BottomCardAddress
                  tel={tel}
                  address={address}
                  www={www}
                  title={title}
                  email={email}
                  extraClass="flex-col flex-col-reverse"
                  type={type}
                >
                  <AddressLink address={address} title={title} />
                </BottomCardAddress>
                <div className="self-end w-40 logos">
                  <Image fluid={thumbnail.childImageSharp.fluid} alt="" />
                </div>
              </div>
            )}
          </header>
          <hr />
          <section
            className="text-article"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          <footer></footer>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        address
        category
        tel
        tags
        city
        email
        type
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 120) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
        www
      }
    }
  }
`
